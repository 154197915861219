import { Box, Grid, Stack, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import * as React from "react";

// components
import Hero from "../../utils/hero";
import Page from "../../utils/page";
import Section from "../../utils/section";
import theme from "../../utils/theme";
import useConfig from "../../utils/useConfig";

// markup
const ChiSiamoPage = () => {
  const siteMetadata = useConfig();
  const schema = [
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Linda Mettifogo",
      jobTitle: "Web & Horeca sales",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Alessio Balenario",
      jobTitle: "Production manager",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
    {
      "@context": "https://schema.org/",
      "@type": "Person",
      name: "Omar Marcolongo",
      jobTitle: "Printing production",
      worksFor: { "@id": `${siteMetadata.siteUrl}/#organization` },
    },
  ];

  return (
    <Page
      lang="en"
      title="Horeca for bars, hotels, and restaurants"
      description="Mettifogo Srl specializes in producing and marketing customized items for the catering industry, such as wet wipes, single-dose sanitizing wipes and gels, sugar sachets, cutlery bags, placemats, fry bags, and menus."
      type="AboutPage"
      schema={schema}
      image="/chi-siamo.jpg"
    >
      <Hero
        title="Serving our customers since 3 generations"
        description="We are always trying to find new ideas, new goals to be one step ahead, keeping tradition in mind but with a modern vision and knowledge of current trends."
        background={theme.palette.primary.main}
        backgroundImage={
          <StaticImage
            style={{ height: "100%" }}
            quality={100}
            src="../../images/backgrounds/fragranza-menta.jpg"
            alt="Mint fragrance"
            layout="fullWidth"
          />
        }
        image={
          <StaticImage
            imgStyle={{ borderRadius: 16 }}
            src="../../images/team/mettifogo-tradizione-da-3-generazioni.jpg"
            alt="Mettifogo serving customers for 3 generations"
            layout="fullWidth"
          />
        }
      />

      <Section>
        <Grid
          spacing={6}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item xs={12} md={5}>
            <StaticImage
              imgStyle={{ borderRadius: 16 }}
              src="../../images/mettifogo-produttore-salviette-personalizzate.jpg"
              alt="Freshness-saving packs"
              layout="fullWidth"
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Stack spacing={1}>
              <Typography variant="h3" component="h3">
                About us
              </Typography>
              <Typography>
                Mettifogo, born as a confectionery distribution company,
                transformed in the late 1980s, adding an important sector, that
                of the production of items for the horeca world, until 1998
                where it inaugurated the line of{" "}
                <strong>disposable wet wipes</strong> with different types of
                flavors such as <strong>lemon wipes</strong>,{" "}
                <strong>lime and cinnamon wipes</strong>, and{" "}
                <strong>mojito wipes</strong>.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Our team
            </Typography>
            <Typography>
              We work together with a planned procedure ensuring our clients the
              best service and valuing each job.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/linda-mettifogo-vendita-horeca-web.jpg"
                alt="Linda Mettifogo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Linda Mettifogo
            </Typography>
            <Typography>Web & Horeca sales</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/alessio-balenario-responsabile-produzione.jpg"
                alt="Alessio Balenario"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Alessio Balenario
            </Typography>
            <Typography>Production manager</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Box mb={3}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/team/omar-macolongo-produzione.jpg"
                alt="Omar Marcolongo"
                layout="fullWidth"
              />
            </Box>
            <Typography
              variant="h5"
              gutterBottom
              component="h2"
              color="primary"
            >
              Omar Marcolongo
            </Typography>
            <Typography>Printing production</Typography>
          </Grid>
        </Grid>
      </Section>

      <Section>
        <Grid spacing={6} container justifyContent="space-between">
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/spedizione-rapida-horeca.jpg"
                alt="Fast shipping throughout Europe"
                layout="fullWidth"
              />
              <Typography variant="h3" component="h3">
                Fast shipping throughout Europe
              </Typography>
              <Typography>
                Through the organization of our transports and with the help of
                the main international carriers, we are able to reach the entire{" "}
                <strong>Italian peninsula</strong> and the{" "}
                <strong>whole of Europe</strong>.
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} md={6}>
            <Stack spacing={1}>
              <StaticImage
                imgStyle={{ borderRadius: 16 }}
                src="../../images/made-in-italy.jpg"
                alt="Made in Italy production"
                layout="fullWidth"
              />

              <Typography variant="h3" component="h3">
                Made in Italy production
              </Typography>
              <Typography>
                Our disposable wet wipes are totally Made in Italy. All the
                products chosen have Italian origin and are of{" "}
                <strong>first quality</strong>, to guarantee the maintenance of
                the <strong>quality standard</strong> of the product over the
                years.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Section>
    </Page>
  );
};

export default ChiSiamoPage;
